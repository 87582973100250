import ReactFlowPro from './reactflowPro/reactflowPro';
import {
  ReactFlowProvider
} from 'reactflow';

const ReactFlowWrapper = (props: any) => {
  return (
    <ReactFlowProvider>
      <ReactFlowPro {...props}/>
    </ReactFlowProvider>
  );
};

export default ReactFlowWrapper;
