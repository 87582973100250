import React, { useEffect, useState } from 'react';
import { useReactFlow, getRectOfNodes, getTransformForBounds } from 'reactflow';
import { toPng } from 'html-to-image';
import { Dimensions } from 'interfaces/subnet.interface';
import { PiDownloadFill } from "react-icons/pi";

const createFileName = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const subnet = queryParams.get('subnet');
    const modifiedSubnet = subnet?.replace(/\//g, "-");
    const sliceto = queryParams.get('sliceto');
    const fileName = `proteus_subnet_slicer_${modifiedSubnet}_${sliceto}`
    return fileName
}
const downloadImage = (dataUrl: any) =>{
    const a = document.createElement('a');
    const fileName = createFileName()
    a.setAttribute('download', `${fileName}.png`);
    a.setAttribute('href', dataUrl);
    a.click();
}


interface DownloadFlowProps {
    dimensions: Dimensions;
}

const DownloadFlow: React.FC<DownloadFlowProps> = ({ dimensions }) => {
    const [treeWidth, setTreeWidth] = useState(dimensions.width);
    const [treeHeight, setTreeHeight] = useState(dimensions.height);

    const { getNodes } = useReactFlow();

    useEffect(() => {
        setTreeWidth(dimensions.width);
        setTreeHeight(dimensions.height);
    }, [dimensions]);

    const onClick = () => {
        // we calculate a transform for the nodes so that all nodes are visible
        // we then overwrite the transform of the `.react-flow__viewport` element
        // with the style option of the html-to-image library
        const nodesBounds = getRectOfNodes(getNodes());
        const transform = getTransformForBounds(nodesBounds, treeWidth, treeHeight, 0.5, 2);
        const element = document.querySelector('.react-flow__viewport') as HTMLElement;


        if (element) {
            toPng(element, {
                backgroundColor: 'white',
                width: treeWidth,
                height: treeHeight,
                style: {
                    width: treeWidth.toString(),
                    height: treeHeight.toString(),
                    transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
                },
            })
                .then((dataUrl) => {
                    downloadImage(dataUrl)
                })
                .catch((error) => {
                    // Handle any errors
                });
        } else {
            console.error('Element not found');
        }


    };

    return (
        <button title="Download flow as png" className='react-flow__controls-button' onClick={onClick}>
            <PiDownloadFill size={25}/>
        </button>
    );
}

export default DownloadFlow;