import { SubnetInfo } from 'interfaces/subnet.interface';

var Netmask = require('netmask').Netmask;

export const slicer = (subnetWithCIDR: any, sliceTo: any): SubnetInfo => {
  try {
    // Compute subnet properties
    var subnet = new Netmask(subnetWithCIDR);
    var subnetInfos: SubnetInfo = {
      subnet: subnet.toString(),
      first: subnet.first,
      last: subnet.last,
      broadcast: subnet.broadcast,
      usable: subnet.size - 2,
      children: [],
    };
    // Compute children if needed
    if (subnet.bitmask >= sliceTo) {
      return subnetInfos;
    }
    var childrenCIDR = subnet.bitmask + 1;
    if (childrenCIDR > 30) {
      return subnetInfos;
    }
    for (
      var childSubnet = new Netmask(subnet.base + '/' + childrenCIDR);
      subnet.contains(childSubnet.base);
      childSubnet = childSubnet.next()
    ) {
      subnetInfos['children'].push(slicer(childSubnet.toString(), sliceTo));
    }
    return subnetInfos;
  } catch (error: any) {
    // If an error is thrown, it will be caught here
    console.error(`Invalid subnet: ${error.message}`);
    return error.message.toString();
  }
};
