export interface SubnetInputs {
    baseSubnet: string;
    sliceLimit: number;
  }
  
  export interface SubnetInfo {
    id?: number;
    name?: string;
    subnet: string;
    first: string;
    last: string;
    usable: number;
    broadcast: string;
    children: SubnetInfo[];
  }

  export enum UriParams {
    subnet = 'subnet',
    sliceto = 'sliceto',
    nodeName = 'nodeName',
  }


  export interface Dimensions {
    width: number,
    height: number
  }


  