// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import styles from './controlPanel.module.scss';
import Draggable from 'react-draggable';
import { BiSolidRightArrow, BiSolidDownArrow } from "react-icons/bi";
import { MdOutlineDragIndicator } from "react-icons/md";

interface ControlPanelProps {
    onSubmit: (subnet: string, slicing: number) => void;
    baseSubnet: string;
    sliceLimit: string;
}

const validateBase = (value: string) => {
    const cidrRegex = /^((\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})\/([1-2]?[0-9]|30)$/;
    return cidrRegex.test(value);
};

export const ControlPanel: React.FC<ControlPanelProps> = ({ onSubmit, baseSubnet, sliceLimit }) => {
    const [subnet, setSubnet] = useState(baseSubnet);
    const [slicing, setSlicing] = useState<number>(Number(sliceLimit));
    const [slicingValue, setSlicingValue] = useState(Number(sliceLimit));

    const [collapsed, setCollapsed] = useState(false);

    const [min, setMin] = useState(parseInt(baseSubnet.split('/')[1], 10));
    const [max, setMax] = useState(parseInt(baseSubnet.split('/')[1], 10) + 8);

    const minVal = parseInt(min, 10);
    const maxVal = parseInt(max, 10);

    const nodeRef = useRef(null);

    // Handler for the range input
    const handleSlicingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // We make sure that the number is within our allowed range
        const newValue = Math.min(Math.max(parseInt(e.target.value, 10), minVal), maxVal);
        if (!isNaN(newValue) && newValue >= minVal && newValue <= maxVal) {
            setSlicing(newValue);
            setSlicingValue(newValue)
            const inputSlicing = document.getElementById('slicingValue');
            if (inputSlicing) {
                inputSlicing.style.border = '1px solid #ddd';
                inputSlicing.style.color = 'black';
            }
        }
    };

    const handleSlicingInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSlicingValue(e.target.value); // Permet à l'utilisateur de taper librement
    };

    // Valide la saisie utilisateur dans slicingValue et met à jour slicing si valide
    const validateAndSetSlicing = () => {
        const newValue = parseInt(slicingValue, 10);
        const inputSlicing = document.getElementById('slicingValue');
        if (!isNaN(newValue) && newValue >= minVal && newValue <= maxVal) {
            setSlicing(slicingValue);
            if (inputSlicing) {
                inputSlicing.style.border = '1px solid #ddd';
                inputSlicing.style.color = 'black';
            }
        } else {
            if (inputSlicing) {
                inputSlicing.style.border = '1px solid red';
                inputSlicing.style.color = 'red';
            }
        }
    };

    useEffect(() => {
        const prefixLength = parseInt(subnet.split('/')[1], 10);
        setMin(prefixLength);
        const calculatedMax = prefixLength + 8;
        setMax(Math.min(calculatedMax, 30));
    }, [subnet]);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const contentStyle = collapsed ? styles.collapsedContent : '';

    const handleSubnetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const InputSubnet = document.getElementById('subnet');
        setSubnet(value);
        if (validateBase(value)) {
            setSlicing(value.split('/')[1]);
            setSlicingValue(value.split('/')[1]);
            if (InputSubnet) {
                InputSubnet.style.border = 'none';
                InputSubnet.style.color = 'black';
            }
        } else {
            if (InputSubnet) {
                InputSubnet.style.border = '2px solid red';
                InputSubnet.style.color = 'red';
            }
        }
    };

    const handleSubmit = () => {
        if (slicingValue !== slicing) {
            setSlicingValue(slicing);
            const InputSubnet = document.getElementById('slicingValue');

            if (InputSubnet) {
                InputSubnet.style.border = 'none';
                InputSubnet.style.color = 'black';
            }
        }
        if (validateBase(subnet)) onSubmit(subnet, slicing); // This is where we call the function passed via props
    };

    return (
        <Draggable
            cancel={`.${styles.cancelDragging}`}
            handle={`.${styles.handle}`}
            defaultPosition={{ x: 0, y: 0 }}
            scale={1}
            nodeRef={nodeRef}
        >
            <div className={`${styles.controlPanel} ${styles.handle}`} ref={nodeRef}>
                <div className={styles.flexAround}>
                    <div className={styles.collapseBar} onClick={toggleCollapse}>
                        {collapsed ? <BiSolidRightArrow size={15} /> : <BiSolidDownArrow size={15} />}
                    </div>
                    <MdOutlineDragIndicator size={18} />
                </div>
                {!collapsed && (
                    <div className={`${styles.cancelDragging} ${styles.controlContent} ${contentStyle} `}>
                        <div className={styles.inputsContainer}>
                            <div className={styles.row}>
                                <div className={styles.label}>Input Subnet</div>
                                <div className={styles.value}>

                                    <input
                                        id="subnet"
                                        className={styles.inputSubnet}
                                        type="text"
                                        value={subnet}
                                        onChange={handleSubnetChange}
                                    />

                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.label}>Slicing</div>
                                <div className={styles.value}>
                                    <div className={styles.slicingContainer}>
                                        <input
                                            id="slicing"
                                            className={styles.inputSlicing}
                                            type="range"
                                            min={min.toString()}
                                            max={max.toString()}
                                            value={slicing}
                                            onChange={handleSlicingChange}
                                        />
                                        <input
                                            id="slicingValue"
                                            className={styles.slicingValue}
                                            type="text"
                                            value={slicingValue}
                                            onChange={handleSlicingInputChange}
                                            onBlur={validateAndSetSlicing}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttonGroup}>
                            <button
                                type="button"
                                className={styles.submitButton}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>)}
            </div>
        </Draggable>
    );
};