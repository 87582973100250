import React, { useEffect, useState } from 'react';
import ReactFlowWrapper from 'components/reactflow/reactflowWrapper';
import { UriParams } from 'interfaces/subnet.interface';
import { slicer } from 'helpers/slicer';
import {
  Node,
  Edge
} from "reactflow";
import { assignIds, getEdges, getNodes, getPositions } from 'helpers/sliceToNode';
import { ControlPanel } from 'components/reactflow/controlPanel/controlPanel';

const defaultSubnteInputs = {
  baseSubnet: '10.0.0.0/21',
  sliceLimit: 24
}

const SubnetPage: React.FC = () => {

  const getUrlParam = (param: UriParams, defaultValue: string) => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(param) || defaultValue;
  };

  const [baseSubnet, setBaseSubnet] = useState(getUrlParam(UriParams.subnet, defaultSubnteInputs.baseSubnet));
  const [sliceLimit, setSliceLimit] = useState(getUrlParam(UriParams.sliceto, defaultSubnteInputs.sliceLimit.toString()));

  const [subnetData, setSubnetData] = useState([slicer(baseSubnet, sliceLimit)]);

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  const [uriParams, setUriParams] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.set(UriParams.subnet, baseSubnet);
    queryParams.set(UriParams.sliceto, sliceLimit);

    const currentSearchParams = window.location.search;
    const newSearchParams = `?${queryParams.toString()}`;
    if (currentSearchParams !== newSearchParams) {
      window.history.pushState(null, '', `${window.location.pathname}${newSearchParams}`);
    }

    const ipParams: { [key: string]: string } = {};

    for (const [key, value] of queryParams) {
      if (key !== "subnet" && key !== "sliceto") {
        ipParams[decodeURIComponent(key)] = decodeURIComponent(value);
      }
    }
    setUriParams(ipParams)
    setSubnetData([slicer(baseSubnet, sliceLimit)])

  }, [baseSubnet, sliceLimit]);

  useEffect(() => {
    if(subnetData) {
    // add ids to sliced subnet
    const subnetIds = assignIds(subnetData, true);
    // format subnet according to react flow format
    const slicedEdges = getEdges(subnetIds);
    const slicedNodes = getNodes(subnetIds);

    // add position to nodes 
    const slicedNodePosition = getPositions(slicedNodes, slicedEdges);

    setNodes(slicedNodePosition);
    setEdges(slicedEdges);
   }
  }, [subnetData])

  const addParamtoUri = (subnet: string, name: string) => {
    const queryParams = new URLSearchParams(window.location.search);

    if (name.trim() === '' ) {
      queryParams.delete(subnet);
    } else if (name && subnet) {
      queryParams.set(subnet, name);
    }
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  const handleControlPanelSubmit = (subnetValue: string, slicingValue: number) => {
    setBaseSubnet(subnetValue);
    setSliceLimit(slicingValue.toString());
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ControlPanel onSubmit={handleControlPanelSubmit} baseSubnet={baseSubnet} sliceLimit={sliceLimit} />
      {nodes.length && <ReactFlowWrapper initialNodes={nodes} initialEdges={edges} addParamtoUri={(subnet: string, name: string) => addParamtoUri(subnet, name)} uriParams={uriParams}/>}
    </div>
  );
};

export default SubnetPage;