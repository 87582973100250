import { useCallback, useEffect, useState } from "react";

import ReactFlow, {
  Node,
  addEdge,
  Background,
  Edge,
  Connection,
  Controls,
  applyEdgeChanges,
  OnEdgesChange,
  OnNodesChange,
  applyNodeChanges,
  MiniMap,
  getNodesBounds,
  useReactFlow
} from "reactflow";
import "reactflow/dist/style.css";
import styles from './styles.module.scss';

import CustomNode from "../customNode/CustomNode";
import DownloadFlow from '../downloadFlow/downloadFlow';

import useExpandCollapse from 'helpers/useExpandCollapse';
import useAnimatedNodes from 'helpers/useAnimatedNodes';
import { Dimensions } from 'interfaces/subnet.interface';

const proOptions = { account: 'paid-pro', hideAttribution: true };

const nodeTypes = {
  custom: CustomNode
};

const minimapStyle = {
  height: 120,
  color: 'black',
};

interface ReactFlowProProps {
  initialNodes: Node[];
  initialEdges: Edge[];
  uriParams: { [key: string]: string };
  addParamtoUri: (subnet: string, name: string) => void;
}

const ReactFlowPro = (
  {
    initialNodes,
    initialEdges,
    uriParams,
    addParamtoUri
  }: ReactFlowProProps) => {
  const [nodes, setNodes] = useState<Node[]>(initialNodes);
  const [edges, setEdges] = useState<Edge[]>(initialEdges);
  const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });
  const [updateCount, setUpdateCount] = useState(0);
  const { setViewport, fitView } = useReactFlow();
  let fitViewOptions = { duration: 1000 };

  const treeWidth = 300;
  const treeHeight = 200;
  const animationDuration = 300;

  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(
    nodes,
    edges,
    { treeWidth, treeHeight }
  );

  useEffect(() => {
    const nodesWithHandlers = initialNodes.map(node => {
      const matchingValue = uriParams[node.data.subnet];

      return {
        ...node,
        data: {
          ...node.data,
          name: matchingValue || node.data.name,
          onExpandClick: () => handleExpand(node.id),
          onNameChange: (name: string) => handelNameChange(node.id, node.data.subnet, name),
          handleNodeClick: (event: React.MouseEvent, node: Node) => handleNodeClick(event, node)
        }
      }
    });

    setNodes(nodesWithHandlers)
    setEdges(initialEdges)
    /* eslint-disable */
  }, [initialNodes])


  useEffect(() => {
    const bounds = getNodesBounds(nodes);
    setDimensions({ width: bounds.width, height: bounds.height });
  }, [nodes]);

  const { nodes: animatedNodes } = useAnimatedNodes(visibleNodes, {
    animationDuration,
  });

  /* eslint-disable */
  const onConnect = useCallback(
    (params: Edge | Connection) => setEdges((els) => addEdge(params, els)),
    [setEdges]
  );

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const handleExpand = useCallback((nodeId: string) => {
    setNodes((currentNodes) =>
      currentNodes.map((node) => {
        if (node.id === nodeId) {
          // Toggle the expanded state
          return {
            ...node,
            data: {
              ...node.data,
              expanded: !node.data.expanded,
            },
          };
        }
        return node;
      })
    );
    setUpdateCount((count) => count + 1);
  }, [setNodes]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (updateCount > 0) { // Ensure this runs only after initial render
        fitView(fitViewOptions);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [updateCount]);

  const handelNameChange = useCallback((nodeId: string, subnet: string, name: string) => {
    if (nodeId && name) {
      // send subnet + name to parent 
      addParamtoUri(subnet, name)

      setNodes((currentNodes) =>
        currentNodes.map((node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                name,
              },
            };
          }
          return node;
        })
      );
    }
    /* eslint-disable */
  }, [setNodes]);


  const handleNodeClick = (event: React.MouseEvent, node: Node) => {
    // Zoom in and center on the node
    setViewport({
      x: -node.position.x - 150 + window.innerWidth / 2,
      y: -node.position.y - 130 + window.innerHeight / 2,
      zoom: 1
    }, { duration: 1000 });
  };

  useEffect(() => {

    const getTimeout = (length: number) => {
      if (length > 500) return 2000;
      if (length > 250) return 1500;
      if (length > 120) return 1000;
      if (length > 100) return 500;
      return 1000; // Valeur par défaut si aucune condition n'est remplie
    };

    const timeout = getTimeout(edges.length);

    const timer = setTimeout(() => {
      fitView(fitViewOptions);
    }, timeout);

    return () => clearTimeout(timer);
  }, [edges])


  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactFlow
        fitView
        nodes={animatedNodes}
        edges={visibleEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeClick={handleNodeClick}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        nodesDraggable={false}
        nodesConnectable={false}
        className={styles.viewport}
        zoomOnDoubleClick={false}
        elementsSelectable={true}
      >
        <Background />
        <Controls>
          <DownloadFlow dimensions={dimensions} />
        </Controls>
        <MiniMap style={minimapStyle} zoomable pannable />
      </ReactFlow>
    </div>
  );
};

export default ReactFlowPro;
