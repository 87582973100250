import { SubnetInfo } from 'interfaces/subnet.interface';
import { Node, Edge, Position } from 'reactflow';
import dagre from 'dagre';

// follow reactflow documentation to set dynamic position
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const nodeWidth = 300;
const nodeHeight = 150;

// to assign ids
let currentId = 0;

const assignIds = (
  subnetInfos: SubnetInfo[],
  isroot: boolean // to define first root and initialise current id in case component was rendered twice
): SubnetInfo[] => {
  return subnetInfos.map((subnetInfo) => {
    if (isroot) {
      currentId = 0;
    }
    const updatedSubnetInfo: SubnetInfo = {
      ...subnetInfo,
      id: ++currentId, // Assign a unique ID
      children: subnetInfo.children
        ? assignIds(subnetInfo.children, false)
        : [], // Recursively assign IDs to children
    };
    return updatedSubnetInfo;
  });
};

// @ts-ignore: Unreachable code error
const getNodes = (
  subnetInfo: SubnetInfo[],
  depth = 1,
  parentIndex = 1
): Node[] => {
  return subnetInfo.reduce<Node[]>((acc, info, index) => {
    // Create the node for the current subnetInfo
    const node: Node = {
      id: info.id ? info.id.toString() : '0',
      data: {
        name: '',
        id: info.id,
        subnet: info.subnet,
        first: info.first,
        last: info.last,
        broadcast: info.broadcast,
        usable: info.usable,
        expandable: !!info.children ? true : false,
        expanded: true, 
        showNodeDetails: false
      },
      type: 'custom',
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      position: { x: 250, y: 5 },
    };
    acc.push(node);

    // If the current subnetInfo has children, transform them as well
    if (info.children && info.children.length > 0) {
      const childrenNodes = getNodes(info.children, depth + 1, index + 1);
      acc.push(...childrenNodes);
    }

    return acc;
  }, []);
};

// @ts-ignore: Unreachable code error
const getEdges = (
  subnetInfos: SubnetInfo[],
  relations: Edge[] = [],
  parentId?: number
): Edge[] => {
  subnetInfos.forEach((subnetInfo) => {
    // If this subnetInfo has a parent, create a relation object
    if (parentId && subnetInfo.id) {
      const relationId = `e${parentId}-${subnetInfo.id}`;
      const relation: Edge = {
        id: relationId,
        source: parentId.toString(),
        target: subnetInfo.id.toString(),
        animated: true,
      };
      relations.push(relation);
    }

    // Recursively process children if there are any
    if (subnetInfo.children && subnetInfo.children.length > 0) {
      getEdges(subnetInfo.children, relations, subnetInfo.id);
    }
  });

  return relations;
};

const getPositions = (
  nodes: Node[],
  edges: Edge[],
  direction = 'TB'
): Node[] => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return nodes;
};

const updateNameForId = (
  nodes: Node[],
  targetId: string,
  newName: string
): Node[] => {
  return nodes.map((node) => {
    if (node) { 
      if (node.data && node.data.id === Number(targetId)) {
        node.data['name'] = newName;
      }
    }
    return node;
  });
};

const updateQuerryToSend = (
  subnetInfo: SubnetInfo[],
  targetId: string,
  newName: string
): SubnetInfo[] => {
  return subnetInfo.map((subnetInfo) => {
    if (subnetInfo.id && subnetInfo.id === Number(targetId)) {
      subnetInfo.name = newName;
    }

    if (subnetInfo.children && subnetInfo.children.length > 0) {
      subnetInfo.children = updateQuerryToSend(
        subnetInfo.children,
        targetId,
        newName
      );
    }

    return subnetInfo;
  });
};

export {
  getNodes,
  getEdges,
  assignIds,
  getPositions,
  updateNameForId,
  updateQuerryToSend,
};
