import { memo, useEffect, useRef, useState } from "react";
import { Handle, NodeProps, Position, useStore } from "reactflow";
import styles from "./customNode.module.scss"
import { FiEdit3, FiSave, FiArrowRight } from "react-icons/fi";

const zoomSelector = (s: any) => s.transform[2] >= 1;

const CustomNode = ({
  data,
  isConnectable,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom
}: NodeProps) => {
  const [editName, setEditName] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const subnet = searchParams.get('subnet'); // This will be '10.0.0.0/21' or `null` if the param is not present

  const showContent = useStore(zoomSelector);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleEditClick = () => {
    setEditName(true)
  }

  useEffect(() => {
    if (inputRef) {
      inputRef.current?.focus();
    }
  }, [editName]);

  const handleOnClick = (event: any) => {
    if (inputRef) {
      const newName = inputRef.current?.value ? inputRef.current?.value : ' ';
      // onNameChange wont take an empty string, for this the empty space is needed ' ' 
      data.onNameChange(newName)
      setEditName(false)
    }
  }

  const handleKeyDown = (event: any) => {
    // Check if Enter key was pressed
    if (event.key === 'Enter') {
      handleOnClick(event);
    }
    if (event.key === 'Escape') {
      setEditName(false)
    }
  };

  // Event handler for clicking on the input
  // Using `event: React.MouseEvent<HTMLInputElement>` to type the event
  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };


  return (
    <>
      {subnet !== data.subnet && <Handle
        type="target"
        position={targetPosition}
        isConnectable={isConnectable}
        id={data.id}
      />}
      <div className={styles.cunstom_node__container}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={styles.cunstom_node__container__title}>
          {/* Display Node Name  */}
          {!editName ?
            <>
              <div className={styles.flex_between}>
                {(data.name.trim() === '') ?
                  <p className={styles.nodeName}>{data.subnet}</p>
                  :
                  <div className={styles.nodeName}>
                    <span title={data.name}>{data.name}</span>
                    <span>  ({data.subnet})</span>
                  </div>
                }
                {(showContent || isHovered) &&
                  <button className={styles.editNameButton} onClick={handleEditClick}>
                    <FiEdit3 size={13} color={'#0F172A'} />
                  </button>
                }
              </div>
            </>
            :
            // Else Edit Node Name 
            <div className={styles.input_container}>
              <input id={data.id} ref={inputRef} placeholder={data.name.length ? data.name : 'Node Name'} type="text" defaultValue={data.name} onKeyDown={handleKeyDown} onClick={handleInputClick} />
              <button className={styles.icon_button} onClick={handleOnClick}>
                <FiSave size={13} color={'#0F172A'} />
              </button>
            </div>
          }
        </div>
        {(showContent || isHovered) &&
          <div className={styles.network_info}>
            <div className={styles.row}>
              <div className={styles.label}>Network IP</div>
              <div className={styles.value}>{data.subnet.split('/')[0]}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>{data.usable} usables IPs</div>
              <div className={styles.value}>{data.first}<FiArrowRight size={10} /> {data.last}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Broadcast IP</div>
              <div className={styles.value}>{data.broadcast}</div>
            </div>
          </div>
        }
      </div>
      {data.expandable && <div className={styles.expand}>
        <button className={styles.addButton} onClick={data.onExpandClick} >
          <span className={styles.collapseLabel}>{data.expanded ? '-' : '+'}</span>
        </button>
      </div>}
      {data.expandable && <Handle
        type="source"
        position={sourcePosition}
        isConnectable={isConnectable}
      />}
    </>
  );
};

CustomNode.displayName = "CustomNode";

export default memo(CustomNode);
