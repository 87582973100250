import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.modules.scss';

import SubnetPage from 'pages/subnet.page';

function App() {

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<SubnetPage />} />
      </Routes>
    </Router>

  );
}

export default App;
